import { Controller } from "stimulus";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
Exporting(Highcharts);

export default class extends Controller {
  static values = {
    data: Object,
    series: Array,
    jobTitles: Array,
  };

  connect() {
    Highcharts.chart(this.element, {
      chart: {
        type: "column",
        height: 600,
        width: 900,
        enableMouseTracking: false,
        shadow: false,
        animation: false
      },
      title: "Calificación Departamento",
      xAxis: {
        categories: this.jobTitlesValue,
        crosshair: true,
      },
      yAxis: {
        max: 100,
        tickInterval: 10,
        title: {
          text: "",
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false
      },
      series: this.seriesValue,
      plotOptions: {
        column: {
          borderWidth: 0,
          groupPadding: 0.1,  // Ajusta el espacio entre las barras
          pointPadding: 0.1,  // Ajusta el espacio dentro de las barras
          animation: false
        },
        series: {
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '10px'
            }
          }
        }
      },
    });
  }
}
